import React from 'react';
import Waves from '../components/waves';
import Navigation from '../components/navigation';

import Footer from '../components/footer';

import '../css/typography.css';

const Policy = () => {
    return (
        <body className="leading-normal tracking-normal text-white gradient">
        <Navigation showButton={true}/>
        <div className="pt-4">
            <Waves/>
        </div>

        <section className="py-6 bg-white text-gray-800">
            <div className="container max-w-4xl mx-auto m-8">
                <h3 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">Cancellation
                    Policy</h3>
                <div className="w-full mb-4">
                    <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"/>
                </div>

                <p className="py-8">We understand that sometimes schedule adjustments are necessary, therefore, we
                    respectfully request at least 24-hour notice for cancellations or rescheduling. Please understand
                    that when an appointment is missed or canceled with insufficient notice, we miss the opportunity to
                    fill that opening, forgoing an opportunity for another client to receive needed treatment. If you do
                    not show for your scheduled
                    appointment time, or cancel/reschedule within 24 hours of your scheduled appointment time,
                    cancellation
                    fees will apply according to our policy below:</p>
                <ul className="list-disc">
                    <li>Canceling/Rescheduling less than 24 hours prior to your appointment will result in a charge
                        equal to
                        100% of the reserved service amount.
                    </li>
                    <li>No-shows will be charged 100% of the reserved service amount.</li>
                    <li>Appointments made within the 24-hour period, that are canceled less than 4 hours prior to the
                        reserved appointment time will be subject to a fee of 100%.
                    </li>
                </ul>

                <p className="py-8">Mexia Massage & Bodywork policies are established to sustain the best quality of service for our
                    established and future clients. Thank you for viewing our policies and criteria.</p>
            </div>
        </section>

        <Footer/>
        </body>
    )
}

export default Policy;
